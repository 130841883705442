.work-list-item {
	position: relative;
	display: flex;
	gap: 24px;
	--workListItemImagesWidthFraction: 0.5;
	width: 100%;
}

.work-list-item:nth-child(even) {
	flex-direction: row-reverse;
}

.work-list-item::after {
	position: absolute;
	bottom: -48px;
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: var(--colour-grey-tertiary);
	opacity: 0.3;
}

.work-list-item:last-child::after {
	display: none;
}

.work-list-item-images-container {
	position: relative;
	--listItemImageHeight: max(
		200px,
		min(
			calc((9 / 16) * var(--workListItemImagesWidthFraction) * 100vw - 2 * var(--page-padding) - - 2 * var(--worklistitem-padding)),
			calc(
				9 / 16 *
					(
						12px + var(--workListItemImagesWidthFraction) *
							(var(--max-width) - 2 * var(--page-padding) - - 2 * var(--worklistitem-padding))
					)
			)
		)
	);

	display: block;
	border-radius: 5px;

	width: calc(100% * var(--workListItemImagesWidthFraction));
	min-height: var(--listItemImageHeight);
	height: var(--listItemImageHeight);
	max-height: var(--listItemImageHeight);
	overflow: hidden;
}

.work-list-item-image-container-placeholder {
	background: var(--colour-grey-secondary);
	background: linear-gradient(90deg, var(--colour-grey-tertiary), var(--colour-grey-secondary));
}

.work-list-item-images {
	display: flex;
	position: relative;
	top: 50%;
	transform: translateX(calc(var(--currImageIndex) * (-100%))) translateY(-50%);
	transition: 0.2s ease-out;
}

.work-list-item-image-container {
	flex-shrink: 0;
	width: 100%;
	height: var(--listItemImageHeight);
	overflow: hidden;
}

.work-list-item-image {
	flex-shrink: 0;
	border-radius: 5px;
	width: 100%;
	min-height: var(--listItemImageHeight);
	height: 100%;
	object-fit: contain;
	cursor: pointer;
}

.work-list-item-images-overlay-swipe-btn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border: 0;
	border-radius: 100%;

	--imagesControlsSwipeBtnWidth: 28px;
	min-width: var(--imagesControlsSwipeBtnWidth);
	width: var(--imagesControlsSwipeBtnWidth);
	min-height: var(--imagesControlsSwipeBtnWidth);
	height: var(--imagesControlsSwipeBtnWidth);

	background: transparent;
	color: var(--text-colour-secondary);
	font-size: 16px;
	transition: 0.2s;
	opacity: 0;
	cursor: pointer;
}

.work-list-item-images-container:hover .work-list-item-images-overlay-swipe-btn,
.work-list-item-images-container:focus .work-list-item-images-overlay-swipe-btn {
	opacity: 0.5;
	background: var(--colour-grey-secondary);
}

.work-list-item-images-overlay-swipe-btn:hover {
	opacity: 1 !important;
	color: var(--text-colour-primary);
}

.work-list-item-images-overlay-swipe-btn-inactive,
.work-list-item-images-container:hover .work-list-item-images-overlay-swipe-btn-inactive,
.work-list-item-images-container:focus .work-list-item-images-overlay-swipe-btn-inactive {
	opacity: 0;
}

.work-list-item-images-overlay-swipe-btn-decrement {
	left: 8px;
}

.work-list-item-images-overlay-swipe-btn-increment {
	right: 8px;
}

.work-list-item-images-overlay-index-text {
	position: absolute;
	bottom: 8px;
	right: 10px;
	padding: 3px 6px;
	border-radius: 5px;

	background: var(--colour-grey-secondary);
	color: var(--text-colour-primary);
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	transition: 0.2s;
	opacity: 0.3;
}

.work-list-item-images-container:hover .work-list-item-images-overlay-index-text {
	opacity: 0.8;
}

.work-list-item-details-container {
	width: 50%;
}

.work-list-item-details {
	margin: 0 auto;
	padding: 0 8px;
	box-sizing: border-box;
	max-width: 640px;
}

.work-list-item-title {
	margin: 0;
	margin-top: 10px;
	margin-bottom: -6px;
	font-size: 33px;
	font-weight: 500;
	letter-spacing: 1px;
	word-spacing: 1px;
	text-align: center;
	white-space: pre-wrap;
}

.work-list-item-second-title {
	margin: 0;
	margin-top: 10px;
	margin-bottom: -6px;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 1px;
	word-spacing: 1px;
	text-align: center;
	white-space: pre-wrap;
}

.work-list-item-links {
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	margin: 24px 0;
	color: var(--text-colour-blue);
	font-weight: 600;
	vertical-align: middle;
}

.work-list-item-links-empty {
	margin: 0;
}

.work-list-item-links-item {
	position: relative;
	padding: 7px 14px;
	border-radius: 32px;

	background: #fff;
	color: #000;
	font-size: 13px;
	font-weight: 600;
	transition: 0.2s;
	cursor: pointer;
}

.work-list-item-links-item:hover {
	scale: 1.04;
}

.work-list-item-links-item div {
	position: relative;
	z-index: 1;
}

.work-list-item-description {
	margin: 24px 0;
	color: var(--text-colour-secondary);
	font-size: 16px;
	font-weight: 300;
	text-align: justify;
	white-space: pre-wrap;
}

.work-list-item-tags-title {
	font-size: 13px;
	letter-spacing: 0.6px;
	word-spacing: 1px;
	color: var(--text-colour-tertiary);
}

.work-list-item-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
	margin: 12px 0;
	width: 100%;
}

.work-list-item-tags-item {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	padding: 0 12px;
	border-radius: 32px;
	border: 1px solid #555;
	background: #000;
	text-align: left;
	height: 25px;
	font-size: 14px;
	font-weight: 500;
}

@media (max-width: 768px) {
	.work-list-item {
		gap: 0;
		flex-direction: column !important;
		--workListItemImagesWidthFraction: 1;
	}

	.work-list-item-details-container {
		width: 100%;
	}

	.work-list-item-title {
		font-size: 26px;
	}

	.work-list-item-second-title {
		font-size: 16px;
	}

	.work-list-item-links {
		margin-top: 24px;
	}

	.work-list-item-links-item {
		font-size: 15px;
	}

	.work-list-item-images-overlay-swipe-btn {
		--imagesControlsSwipeBtnWidth: 32px;
		font-size: 20px;
	}

	.work-list-item-tags {
		margin-bottom: 0;
	}
}
