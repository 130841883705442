.footer {
	margin: 48px auto;
	margin-bottom: 0;
	padding: 0 var(--page-padding);
	padding-bottom: 48px;
	width: calc(100% - (2 * var(--page-padding)));
	max-width: var(--max-width);
	font-weight: 300;
}

.footer-title {
	position: relative;
	margin-bottom: 24px;
	padding-left: 12px;
	font-size: 28px;
	font-weight: 300;
}

.footer-section-1 {
	display: flex;
	flex-wrap: wrap;
	gap: 12px 48px;
	padding: 0 16px;
	box-sizing: content-box;
}

.footer-email-title,
.footer-links-title,
.footer-download-cv-btn-title {
	margin-bottom: 2px;
	font-size: 15px;
	color: var(--text-colour-secondary);
}

.footer-email {
	line-height: 36px;
	font-size: 17px;
}

.footer-links {
	display: flex;
	align-items: center;
	--footerLinkMargin: 8px;
	margin-left: calc(-1 * var(--footerLinkMargin));
	width: calc(100% + (2 * var(--footerLinkMargin)));
}

.footer-link {
	margin: var(--footerLinkMargin);
	padding: 0;
	height: 24px;
	background: transparent;
	transition: 0.2s;
	filter: brightness(100%) invert(1) brightness(75%);
	cursor: pointer;
}

.footer-link:hover {
	filter: brightness(100%) invert(1);
}

.footer-link-image {
	height: inherit;
	object-fit: contain;
}

.footer-download-cv-btn-container {
	display: flex;
	align-items: center;
}

.footer-download-cv-btn {
	position: relative;
	display: flex;
	align-items: center;
	margin: 5px 0;
	padding: 7px 16px;
	border-radius: 32px;
	background: #fff;
	color: #000;
	transition: 0.2s;
	font-size: 15px;
	cursor: pointer;
}

.footer-download-cv-btn:hover {
	scale: 1.04;
}

.footer-download-cv-btn-content {
	z-index: 1;
	display: flex;
	gap: 8px;
	align-items: center;
}

.footer-download-cv-btn-label {
	font-weight: 600;
}

.footer-download-cv-btn-icon {
	font-size: 12px;
}

.footer-copyright {
	display: flex;
	align-items: center;
	gap: 0 4px;
	margin-top: 16px;
	padding: 0 16px;
	box-sizing: content-box;
	font-size: 12px;
	color: var(--text-colour-secondary);
	opacity: 0.3;
}

.footer-copyright-symbol {
	margin-bottom: -1px;
	font-size: 14px;
}

@media (max-width: 768px) {
	.footer-title {
		font-size: 32px;
	}
}
