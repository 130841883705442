.home-technologies-list-container {
	position: relative;
	margin: 24px 0;
	padding: 24px 0;
	border-radius: 5px;

	width: 100%;
}

.home-technologies-list-title {
	position: relative;
	margin-bottom: 24px;
	padding-left: 12px;
	font-size: 28px;
	font-weight: 300;
}

.home-technologies-list-level {
	margin: 24px 0;
	padding: 0 16px;
	box-sizing: content-box;
}

.home-technologies-list-level-competence {
	margin-bottom: 12px;
	font-size: 16px;
	font-weight: 300;
}

.home-technologies-list-items {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
	width: 100%;
}

.home-technologies-list-item {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	padding: 0 14px;
	border-radius: 32px;
	border: 1px solid #555;
	background: #000;
	text-align: left;
	height: 30px;
	font-size: 14px;
	font-weight: 500;
}

.home-technologies-list-item-image {
	margin-right: 8px;
	min-width: 22px;
	max-height: 22px;
	object-fit: contain;
}

@media (max-width: 768px) {
	.home-technologies-list-container {
		margin: 24px 0;
		padding: 0;
	}

	.home-technologies-list-title {
		font-size: 32px;
	}

	.home-technologies-list-level {
		margin: 24px 0;
	}

	.home-technologies-list-level-competence {
		margin-bottom: 6px;
	}

	.home-technologies-list-item {
		padding: 5px 10px;
		border-radius: 8px;
	}

	.home-technologies-list-item-image {
		max-height: 24px;
	}

	.home-technologies-list-item-title {
		font-size: 14px;
	}
}
