.home-page .work-list-container {
	margin-top: 24px;
	margin-bottom: 24px;
}

.home-page .work-list-title {
	position: relative;
	margin-bottom: 12px;
	font-size: 28px;
	font-weight: 300;
}

.home-page .work-list-items {
	--worklistitem-padding: 0px;
}

@media (max-width: 768px) {
	.home-page .work-list-title {
		font-size: 32px;
	}

	.home-page .work-list-items {
		--worklistitem-padding: 0px;
	}
}
