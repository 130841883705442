.header {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	display: flex;
	align-items: center;
	margin: 0 auto;

	width: calc(100vw - 24px);
	width: calc(100vw - (2 * var(--page-padding)));
	height: 70px;
	max-width: var(--max-width);
}

.header::before {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
	display: block;
	content: "";

	width: 100vw;
	height: 70px;

	background: var(--colour-grey-primary);
}

.header-name {
	position: relative;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	background: transparent;
	color: var(--text-colour-primary);
	font-size: 22px;
	font-weight: 600;
	transition: 0.2s;
	cursor: pointer;
	text-align: left;
}

.header-name-text {
	font-weight: 500;
	background: linear-gradient(180deg, #04f -50%, #cdf);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.header-name-image {
	margin-right: 12px;
	padding-bottom: 1px;
	width: 30px;
}

.header-name-image-waving-hand-emoji,
.header-name-image-waving-hand-emoji-initial {
	position: absolute;
	z-index: 4;
	top: 8px;
	left: 12px;
	font-size: 24px;
	pointer-events: none;
	opacity: 0;
}

.header-name-image-waving-hand-emoji-initial {
	animation: header-name-image-waving-hand-emoji-animation 2.2s ease-in;
}

.header-name:hover .header-name-image-waving-hand-emoji,
.header-name:focus .header-name-image-waving-hand-emoji,
.header-name-play-animation .header-name-image-waving-hand-emoji {
	animation: header-name-image-waving-hand-emoji-animation 2.2s infinite ease-in;
}

@keyframes header-name-image-waving-hand-emoji-animation {
	0% {
		transform: scale(-1, 1);
		opacity: 0;
	}
	10% {
		transform: scale(-1, 1);
		opacity: 1;
	}
	15% {
		transform: scale(-1, 1) rotate(6deg);
		opacity: 1;
	}
	20% {
		transform: scale(-1, 1) rotate(-6deg);
		opacity: 1;
	}
	25% {
		transform: scale(-1, 1) rotate(6deg);
		opacity: 1;
	}
	30% {
		transform: scale(-1, 1) rotate(-6deg);
		opacity: 1;
	}
	40% {
		transform: scale(-1, 1);
		opacity: 1;
	}
	50% {
		transform: scale(-1, 1);
		opacity: 0;
	}
	100% {
		transform: scale(-1, 1);
		opacity: 0;
	}
}

.header-hamburger {
	position: relative;
	display: none;
	margin-left: auto;
	padding: 0;
	--hamburgerWidth: 26px;
	width: var(--hamburgerWidth);
	height: 22px;
	background: transparent;
}

.header-hamburger-line {
	position: absolute;
	display: block;
	margin: 0;
	border-radius: 1px;
	content: "";
	width: var(--hamburgerWidth);
	height: 3px;
	background: var(--text-colour-secondary);
	transition: 0.2s, opacity 0s;
}

.header-is-displaying-pages .header-hamburger-line {
	border-radius: 2px;
	transition: 0.2s;
}

.header-hamburger-line:nth-child(1) {
	top: 1px;
}

.header-hamburger-line:nth-child(2) {
	top: 50%;
	transform: translateY(-50%);
}

.header-hamburger-line:nth-child(3) {
	bottom: 1px;
}

.header-is-displaying-pages .header-hamburger-line:nth-child(1) {
	animation: header-is-displaying-pages-hamburger-line-top-animation 0.3s linear;
	animation-fill-mode: forwards;
}

@keyframes header-is-displaying-pages-hamburger-line-top-animation {
	0% {
		top: 0;
	}
	30% {
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
		-webkit-transform: translateY(-50%) rotate(0deg);
	}
	100% {
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
		-webkit-transform: translateY(-50%) rotate(45deg);
	}
}

.header-is-displaying-pages .header-hamburger-line:nth-child(2) {
	opacity: 0;
}

.header-is-displaying-pages .header-hamburger-line:nth-child(3) {
	animation: header-is-displaying-pages-hamburger-line-bottom-animation 0.3s linear;
	animation-fill-mode: forwards;
}

@keyframes header-is-displaying-pages-hamburger-line-bottom-animation {
	0% {
		top: 0;
	}
	30% {
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
		-webkit-transform: translateY(-50%) rotate(0deg);
	}
	100% {
		top: 50%;
		transform: translateY(-50%) rotate(-45deg);
		-webkit-transform: translateY(-50%) rotate(-45deg);
	}
}

.header-pages {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
}

.header-pages-btn {
	position: relative;
	margin: 0 12px;
	border-radius: 14px;

	background: transparent;
	color: var(--text-colour-tertiary);
	font-family: "Muli", -apple-system, "Segoe UI", sans-serif !important;
	font-size: 15px;
	font-weight: 700;
	transition: 0.2s;
	cursor: pointer;
}

.header-pages-btn:hover,
.header-pages-btn:focus {
	color: var(--text-colour-primary);
}

.header-pages-btn-active,
.header-pages-btn-active:hover,
.header-pages-btn-active:focus {
	color: var(--text-colour-primary);
}

.header-pages-btn-active:hover,
.header-pages-btn-active:focus {
	color: var(--text-colour-primary);
}

.header-pages-btn-active::after {
	position: absolute;
	bottom: -7px;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	content: "";
	border-radius: 2px;
	width: max(24px, calc(100% - 28px));
	height: 4px;
	background: #0044ff;
}

.header-links {
	display: flex;
	gap: 24px;
	margin-left: auto;
}

.header-link {
	position: relative;
	margin: 0;
	padding: 0;
	height: 24px;
	background: transparent;
	cursor: pointer;
}

.header-link img {
	filter: brightness(100%) invert(1) brightness(75%);
	transition: 0.2s;
}

.header-link:hover img {
	filter: brightness(100%) invert(1);
}

.header-link-image {
	border-radius: 2px;
	height: inherit;
	object-fit: contain;
}

.header-link-tooltip {
	position: absolute;
	top: 28px;
	right: -12px;
	padding: 6px 10px;
	border: 1px solid #555;
	border-radius: 5px;
	background: var(--colour-grey-secondary);
	color: #fff;
	text-align: left;
	transition: 0.2s;
	opacity: 0;
	line-height: 1.1rem;
	pointer-events: none;
}

.header-link:hover .header-link-tooltip,
.header-link .header-link-tooltip:hover,
.header-link .header-link-tooltip:focus {
	opacity: 1;
	pointer-events: all;
	transition-delay: 0s;
}

.header-pages-links {
	display: none;
}

@media (max-width: 1100px) {
	.header-pages {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		top: 1px;
		left: unset;
		transform: translate(0%, 0%);
	}
}

@media (max-width: 900px) {
	.header-name {
		font-size: 28px;
	}

	.header-name-image {
		margin-right: 10px;
	}

	.header-hamburger {
		display: block;
	}

	.header-pages {
		position: absolute;
		top: 87px;
		left: 50%;
		transform: translateX(-50%);
		flex-direction: column;
		margin: 0;

		max-height: 0;
		width: 100vw;
		overflow: hidden;
		background: var(--colour-grey-primary);

		transition: 0.1s ease, padding 0.1s linear;
	}

	.header-is-displaying-pages .header-pages {
		padding: 0 0 12px 0;
		height: fit-content;
		max-height: var(--full-vh);
		transition: 0.4s ease, padding 0.1s linear;
		overflow: visible;
	}

	.header-is-displaying-pages .header-pages-background {
		position: fixed;
		z-index: -1;
		top: 87px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		content: "";
		width: 100vw;
		height: calc(100vh - 87px);
		background: #0c0c0cbb;
		backdrop-filter: blur(3px);
		transition: background 0.2s;
	}

	.header-pages-btn {
		margin: 0;
		padding: 10px 20px;
		text-align: left;
		border-radius: 0;
		font-size: 18px;
	}

	.header-pages-btn-active::after {
		display: none;
	}

	.header-pages-links {
		display: flex;
		padding: 10px 20px;
		gap: 20px;
	}

	.header-links {
		display: none;
	}
}
