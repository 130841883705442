.work-page .work-list-container {
	margin-top: 12px;
}

.work-page .work-list-title {
	margin-bottom: 24px;
	font-size: 28px;
	font-weight: 300;
}

@media (max-width: 768px) {
	.work-page .work-list-container {
		margin-top: 0;
	}
}
