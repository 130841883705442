.page-content {
	margin: 0 auto;
	padding: 0 var(--page-padding);
	padding-top: 87px;
	width: calc(100% - (2 * var(--page-padding)));
	max-width: var(--max-width);
	min-height: var(--full-vh);
}

.page::before {
	position: fixed;
	z-index: -1;
	top: -90px;
	right: -400px;
	display: block;
	content: "";
	width: 800px;
	height: 1000px;
	background: radial-gradient(var(--colour-blue), #0000 50%);
	opacity: 0.25;
}

@media (max-width: 600px) {
	.page::before {
		top: -90px;
		right: -450px;
	}
}

.page::after {
	position: fixed;
	z-index: -1;
	bottom: -100px;
	left: -400px;
	display: block;
	content: "";
	width: 800px;
	height: 1000px;
	background: radial-gradient(var(--colour-blue), #0000 50%);
	opacity: 0.2;
}

@media (max-width: 600px) {
	.page::after {
		bottom: -140px;
		left: -450px;
	}
}

.page-background {
	position: fixed;
	z-index: -2;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	overflow: hidden;
	pointer-events: none;
	user-select: none;
}

.page-background-hidden .page-background {
	display: none;
}

.page-background-emojis,
.page-background-emoji-stars {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: "Segoe UI Emoji", "Noto Color Emoji", sans-serif;
	overflow: visible;
}

.page-background-emoji {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-15deg);
	opacity: 0.18;
}

.page-background-emoji-container {
	position: absolute;
	font-size: 600px;
}

.page-background-emoji-container:nth-child(1) {
	top: -80px;
	left: 400px;
	transform: rotate(-10deg);
	font-size: 640px;
	filter: hue-rotate(15deg);
}

.page-background-emoji-container:nth-child(2) {
	top: -280px;
	right: 540px;
	font-size: 245px;
	transform: rotate(30deg);
	filter: hue-rotate(15deg);
}

@media (max-width: 1350px) {
	.page-background-emoji-container:nth-child(2) {
		top: -280px;
		right: 35dvw;
	}
}

@media (max-width: 600px) {
	.page-background-emoji-container:nth-child(2) {
		transform: rotate(35deg);
		right: 28dvw;
		font-size: 210px;
	}
}

.page-background-emoji-container:nth-child(3) {
	top: 270px;
	left: 200px;
	font-size: 120px;
	transform: rotate(5deg);
}

.page-background-emoji-container:nth-child(4) {
	top: -40px;
	right: 220px;
	font-size: 140px;
	transform: scaleX(-1) rotate(-10deg);
	opacity: 0.7;
}

@media (max-width: 1450px) {
	.page-background-emoji-container:nth-child(4) {
		top: 180px;
		right: 530px;
	}
}

@media (max-width: 1200px) {
	.page-background-emoji-container:nth-child(4) {
		right: 42dvw;
	}
}

.page-background-emoji-container:nth-child(5) {
	top: 180px;
	right: 530px;
	font-size: 130px;
	transform: rotate(20deg);
}

@media (max-width: 1450px) {
	.page-background-emoji-container:nth-child(5) {
		display: none;
	}
}

.page-background-emoji-container:nth-child(6) {
	top: 330px;
	left: 700px;
	font-size: 150px;
	transform: rotate(25deg);
}

@media (max-width: 1450px) {
	.page-background-emoji-container:nth-child(6) {
		top: -40px;
		left: unset;
		right: 220px;
	}
}

@media (max-width: 1200px) {
	.page-background-emoji-container:nth-child(6) {
		right: 120px;
	}
}

.page-background-emoji-container:nth-child(7) {
	top: 240px;
	right: 260px;
	font-size: 100px;
	transform: rotate(-4deg);
}

@media (max-width: 1200px) {
	.page-background-emoji-container:nth-child(7) {
		display: none;
	}
}

.page-background-emoji-container:nth-child(8) {
	top: 22px;
	right: 520px;
	font-size: 90px;
	transform: scaleX(-1) rotate(20deg);
	opacity: 0.7;
}

@media (max-width: 1450px) {
	.page-background-emoji-container:nth-child(8) {
		display: none;
	}
}

.page-background-emoji-star-container {
	position: absolute;
	opacity: 0.5;
}

.page-background-emoji-star-container:nth-child(1) {
	top: -195px;
	right: 35px;
	transform: rotate(25deg);
	font-size: 50px;
}

.page-background-emoji-star-container:nth-child(2) {
	top: 300px;
	right: 100px;
	transform: rotate(35deg);
	font-size: 50px;
}

.page-background-emoji-star-container:nth-child(3) {
	top: 75px;
	right: 670px;
	transform: rotate(0deg);
	font-size: 30px;
}

.page-background-emoji-star-container:nth-child(4) {
	top: -310px;
	left: 720px;
	transform: rotate(35deg);
	font-size: 50px;
}

.page-background-emoji-star-container:nth-child(5) {
	top: 380px;
	left: 425px;
	transform: rotate(20deg);
	font-size: 30px;
}

.page-background-emoji-star-container:nth-child(6) {
	top: -15px;
	right: 10px;
	transform: rotate(-5deg);
	font-size: 30px;
}

@media (max-width: 768px) {
	.page-content {
		padding-top: 87px;
	}
}
